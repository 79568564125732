import { AtomLink } from 'atoms/AtomLink'
import axios from 'axios'
import { Colors, StyledP } from 'commons/Theme'
import { Html } from 'components/App/Html'
import { UserLoginProvider } from 'contexts/UserLoginContext'
import {
  ColumnLeft,
  ColumnRight,
  StyledBodyMessage,
  StyledPage,
  StyledPageBody,
  StyledPageGrid,
  StyledPageSubtitle,
  StyledPageTitle,
} from 'pages-style/404.style'
import { useEffect, useState } from 'react'
import { A2ADataContextProvider } from '../contexts/A2ADataContext'
import { LayoutContextProvider } from '../contexts/LayoutContext'
import { MaintenanceSVGImage } from '../pages-style/maintenanceSVG'
import { removeTrailingSlashes } from 'commons/utils'
import { SimpleHeader } from 'components/Header/SimpleHeader'

const endpointUrl = `${removeTrailingSlashes(
  process.env.A2A_DRUPAL_CASA_URL
)}/a2a-api/login-resource?_format=json`

const ServizioManutenzioneAreaClienti = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [maintenanceMessage, setMaintenanceMessage] = useState<string | null>(
    null
  )
  const [showDefaultMessage, setShowDefaultMessage] = useState<boolean>(false)

  const defaultMessage = (
    <>
      <StyledPageSubtitle>Stiamo lavorando per te!</StyledPageSubtitle>
      <StyledP $color="grey100">
        La pagina che stai cercando è in manutenzione e tornerà presto
        disponibile.
      </StyledP>
    </>
  )

  useEffect(() => {
    const getMaintenanceMessage = async () => {
      setIsLoading(true)

      try {
        const res = await axios.get(endpointUrl)

        if (res.data && res.data?.maintenance_clients_area?.page_body?.value) {
          setShowDefaultMessage(false)
          setMaintenanceMessage(
            res.data?.maintenance_clients_area?.page_body?.value
          )
        } else {
          setShowDefaultMessage(true)
        }
      } catch (error) {
        setShowDefaultMessage(true)
      }

      setIsLoading(false)
    }

    getMaintenanceMessage()
  }, [])

  return (
    <A2ADataContextProvider>
      <UserLoginProvider>
        <LayoutContextProvider>
          <StyledPage
            className="std std-light"
            $backgroundColor={Colors.blue800}
          >
            <SimpleHeader backgroundColor={Colors.blue800} />
            <StyledPageBody>
              <StyledPageGrid cols={1} desktopCols={2} rowsGap="0">
                <ColumnLeft>
                  {!isLoading && (
                    <>
                      <StyledPageTitle>Ops!</StyledPageTitle>
                      {showDefaultMessage && <>{defaultMessage}</>}
                      {!showDefaultMessage && maintenanceMessage !== null && (
                        <StyledBodyMessage>
                          <Html>{maintenanceMessage}</Html>
                        </StyledBodyMessage>
                      )}
                      <AtomLink
                        label="Vai alla homepage"
                        to="/"
                        styleType="ghost"
                        color="white"
                      />
                      <AtomLink
                        label="Vai all'assistenza"
                        to="/assistenza"
                        styleType="ghost"
                        color="white"
                      />
                    </>
                  )}
                </ColumnLeft>
                <ColumnRight>
                  <MaintenanceSVGImage />
                </ColumnRight>
              </StyledPageGrid>
            </StyledPageBody>
          </StyledPage>
        </LayoutContextProvider>
      </UserLoginProvider>
    </A2ADataContextProvider>
  )
}

export default ServizioManutenzioneAreaClienti
